
import { isUndefined } from "lodash";
import Vue from "vue";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";

export default Vue.extend({
  name: "CreateTabs",
  props: {
    items: {
      type: Array,
      default: function () {
        return [] as any[];
      },
    },
    current_tab: {
      type: Number,
      default: 0,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    custom_class: {
      type: String,
      default: "v-tab--targeting",
    },
    slider_size: {
      type: Number,
      default: 4,
    },
    color: {
      type: String,
      default: "secondary",
    },
    grow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IconBase,
  },
  data: () => ({}),
  created() {},
  async mounted() {},
  computed: {
    currentTab: {
      set(val) {
        this.$emit("update-selected-tab-index", val);
      },
      get() {
        return this.current_tab;
      },
    },
  },
  methods: {
    getUrl(item: { src: String }) {
      return item.src;
    },
    hasData(data: any) {
      return !isUndefined(data);
    },
    getIconType(key: number): string {
      const iconMap = new Map<number, string>([
        [0, "app-site"],
        [1, "content"],
        [2, "environment"],
        [3, "exchange"],
        [4, "geo"],
        [5, "platform"],
        [6, "time"],
        [7, "user"],
      ]);

      return iconMap.get(key) ?? "";
    },

    getClass(disabled: boolean) {
      return `${this.custom_class} itemtab ${disabled ? "disabled" : ""}`;
    },
  },
});
